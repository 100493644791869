export default class HistoricoConsignacaoService {
  constructor(http) {
    this._http = http
  }

  async getHistoricoConsignacaoById(id) {
    const { data } = await this._http.get(`/api/historico_consignacao/${id}`)
    return data
  }

  async getHistoricoConsignacao(id) {
    const { data } = await this._http.get(
      `/api/historico_consignacao/consignacao/${id}`,
    )
    return data
  }

  async getHistoricoConsignacaoByReservaCartao(reservaCartaoId) {
    const { data } = await this._http.get(
      `/api/historico_consignacao/reserva_cartao/${reservaCartaoId}`,
    )
    return data
  }

  async saveHistoricoConsignacao(historicoConsignacao) {
    return await this._http.post(
      '/api/historico_consignacao',
      historicoConsignacao,
    )
  }
}
