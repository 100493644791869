export default class ReservaCartaoService {
  constructor(http) {
    this._http = http
  }

  async getListaReservaCartaoPorMatricula(id) {
    const { data } = await this._http.get(`/api/reserva-cartao/matricula/${id}`)
    return data
  }

  async getListaReservaCartaoPorConsignatariaSelecionada(id, consignatariaId) {
    const { data } = await this._http.get(
      `/api/reserva-cartao/consignataria/${consignatariaId}/matricula/${id}`,
    )
    return data
  }

  async getReservaCartaoById(id) {
    const { data } = await this._http.get(`/api/reserva-cartao/${id}`)
    return data
  }

  async saveReservaCartao(reservaCartao, consignatariaId) {
    if (reservaCartao.id) {
      return await this._http.put(
        `/api/reserva-cartao/consignataria/${consignatariaId}`,
        reservaCartao,
      )
    } else {
      return await this._http.post(
        `/api/reserva-cartao/consignataria/${consignatariaId}`,
        reservaCartao,
      )
    }
  }

  async ativarReservarCartao(id, historicoConsignacao) {
    if (id) {
      const { data } = await this._http.patch(
        `/api/reserva-cartao/ativar/${id}`,
        historicoConsignacao,
      )
      return data
    }
  }
  async suspenderReservarCartao(id, historicoConsignacao) {
    if (id) {
      const { data } = await this._http.patch(
        `/api/reserva-cartao/suspender/${id}`,
        historicoConsignacao,
      )
      return data
    }
  }
  async cancelarReservarCartao(id) {
    if (id) {
      const { data } = await this._http.delete(`/api/reserva-cartao/${id}`)
      return data
    }
  }
}
