export default class HistoricoConsignacao {
  constructor(
    id = null,
    reservaCartaoId = null,
    tipo = null,
    motivo = null,
    observacao = null,
    dataDocumento = null,
    numeroOficio = null,
    numeroProcesso = null,
    juizado = null,
    nomeJuiz = null,
    alteradoPor = null,
    cadastradoPor = null,
    consignacao = null,
  ) {
    this.id = id
    this.reservaCartaoId = reservaCartaoId
    this.tipo = tipo
    this.motivo = motivo
    this.observacao = observacao
    this.dataDocumento = dataDocumento
    this.numeroOficio = numeroOficio
    this.numeroProcesso = numeroProcesso
    this.juizado = juizado
    this.nomeJuiz = nomeJuiz
    this.alteradoPor = alteradoPor
    this.cadastradoPor = cadastradoPor
    this.consignacao = consignacao
  }
}
